import React from 'react';
import { DateTimePicker } from 'react-rainbow-components';

export const EventTypeSelect = ({
  eventTypeValue='',
  setEventTypeValue
}) => (
  <div className="field">
        <label className="label" htmlFor="event-type">Event Type</label>
        <div className="control">
          <div className="select">
            <select
              id="event-type"
              name="event-type"
              className=""
              value={eventTypeValue}
              onChange={e => setEventTypeValue(e.target.value)}
            >
              <option>Birthday</option>
              {/* <option>Farewell</option>
              <option>Anniversary</option> */}
            </select>
        </div>
        </div>
      </div>
);

export const PersonNameInput = ({
  personNameValue='',
  setPersonNameValue
}) => {
  return (
    <div className="field">
        <label className="label" htmlFor="person-name">Person's Name</label>
        <div className="control">
          <input
            id="person-name"
            name="person-name"
            type="text"
            placeholder="Who's the lucky person?"
            className="input"
            value={personNameValue}
            required={true}
            maxLength={15}
            onChange={e => setPersonNameValue(e.target.value)}
          />
        </div>
      </div>
  )
}

export const GreetingMessageInput = ({
  greetingMessageValue='',
  setGreetingMessageValue
}) => {
  return (
    <div className="field">
        <label className="label" htmlFor="person-name">Greeting Message</label>
        <div className="control">
          <input
            id="event-greeting"
            name="event-greeting"
            type="text"
            placeholder="eg. Happy Birthday Sonia - world's best sister !"
            className="input"
            value={greetingMessageValue}
            onChange={e => setGreetingMessageValue(e.target.value)}
          />
        </div>
      </div>
  )
}

export const EventDatePicker = ({
  dateValue,
  setDateValue
}) => {
  return (
    <div className="field">
        <label className="label" htmlFor="event-time">Event Time</label>
        <DateTimePicker
          value={dateValue}
          onChange={setDateValue}
        />
    </div>
  )
}

export const EventSubmitButton = ({isLoading}) => (
  <div className="field">
    <label className="label" htmlFor="event-submit"></label>
    <div className="control">
      <button
        type="submit"
        id="event-submit"
        name="event-submit"
        className={"button is-primary mt-3" + (isLoading ? " is-loading": "")}
      >Get Sharable Link</button>
    </div>
  </div>
);
