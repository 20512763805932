import React, { useState } from 'react';
import { Link } from "gatsby";
import CreatePartyModal from '../components/createParty/modal';
import '../config/firebaseConfig';
import Layout from '../components/Layout'
import FeedbackModal from '../components/feedback/modal';
import FloatingActionButton, { iconTypes } from '../components/FAB';
import AboutModal from '../components/about/modal';
import { EventLabels, EventNames, sendClickEvent } from '../utils/analytics';

export default () => {
  const [isCreatePartyModalShown, showCreatePartyModal] = useState(false);
  const [isFeedbackModalShown, setIsFeedbackModalShown] = useState(false);
  const [isAboutModalShown, setIsAboutModalShown] = useState(false);

  return (
    <Layout>
      <div className="hero is-fullheight has-background-pink">
        <CreatePartyModal
          isModalActive={isCreatePartyModalShown}
          onClose={() => showCreatePartyModal(false)}
        />
        <FeedbackModal
            isModalActive={isFeedbackModalShown}
            onClose={() => setIsFeedbackModalShown(false)}
        />
        <AboutModal
          isModalActive={isAboutModalShown}
          onClose={() => setIsAboutModalShown(false)}
        />
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title block is-spaced">
              BirthdayCovid.com
            </h1>
            <h2 className="subtitle block">
              Wish your loved one and share them a link to cut their virtual cake online
            </h2>
            <div className="columns block mt-6">
              <div className="column">
                <Link to="/party" className="button is-fullwidth is-large" onClick={()=>sendClickEvent(EventLabels.CTA, EventNames.SEE_DEMO_CLICKED)}>
                  See demo
                  </Link>
              </div>
              <div className="column">
                <button
                  className="button is-fullwidth is-large is-primary"
                  onClick={() => {
                    sendClickEvent(EventLabels.CTA, EventNames.CREATE_PARTY_CLICKED)
                    showCreatePartyModal(true)
                  }}
                >Create virtual party for friend</button>
              </div>      
            </div>
          </div>
        </div>
      </div>
      <FloatingActionButton
        iconType={iconTypes.FEEDBACK}
        onClick={() => {
          sendClickEvent(EventLabels.FAB, EventNames.FEEDBACK_CLICKED)
          setIsFeedbackModalShown(true)
        }}
        corner={'bottom-left'}
      />
      <FloatingActionButton
        iconType={iconTypes.ABOUT}
        onClick={() => {
          sendClickEvent(EventLabels.FAB, EventNames.ABOUT_CLICKED)
          setIsAboutModalShown(true)
        }}
        corner={'bottom-right'}
      />
    </Layout>
  )
}