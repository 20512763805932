import React from 'react';
// import CreatePartyForm from '../form';

const AboutModal = ({ isModalActive, onClose }) => {

  return (
    <div className={`modal ${isModalActive ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={onClose}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">About</p>
            <button
              className="delete"
              aria-label="close"
              onClick={onClose}
            ></button>
          </header>
          <section className="modal-card-body">
            <p>Celebrate all the happy occasions of your loved ones remotely</p>
            <p>Click on "Create Virtual Party for friend" and get a link you can share with the birthday person on whatsapp/ facebook for them to cut their cake virtually!</p>
          </section>
          <footer className="modal-card-foot"/>
        </div>
      </div>
  );
}

export default AboutModal;