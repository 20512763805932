import React, { useState } from 'react';
import { EventDatePicker, EventSubmitButton, EventTypeSelect, GreetingMessageInput, PersonNameInput } from './formInputs';
import copyToClipboard from '../../../utils/clipboard'
import { createParty } from '../../../repository/party'
import { EventLabels, EventNames, sendClickEvent, sendCustomEvent } from '../../../utils/analytics';
import { getRandomCode } from '../../../utils/helpers';

const CreatePartyForm = () => {

  const [formData, setFormData] = useState({
    event_type: 'Birthday',
    event_time: new Date(),
    person_name:'',
    personal_msg: '',
    version: 2
  });

  const [shareableLink, setShareableLink] = useState('')

  const [shareableLinkFriends, setShareableLinkFriends] = useState('')

  const [formSubmitError, setFormSubmitError] = useState('')

  const [isFormLoading, setIsFormLoading] = useState(false)

  const [isLinkCopiedSuccess, setIsLinkCopiedSuccess] = useState(false)

  const [isLinkFriendsCopiedSuccess, setIsLinkFriendsCopiedSuccess] = useState(false)

  const onFormSubmit = (e) => {
    e.preventDefault();
    sendClickEvent(EventLabels.CTA, EventNames.GET_LINK_CLICKED)
    setIsFormLoading(true)
    let code = getRandomCode(4)
    
    createParty(formData, code)
      .then(partyId => {
        const siteUrl = window.location && window.location.origin
        sendCustomEvent(EventLabels.Event, EventNames.GET_LINK_CLICKED_SUCCESS)
        setShareableLink(`${siteUrl}/party/${partyId}?code=${code}`)
        setShareableLinkFriends(`${siteUrl}/party/${partyId}`)
        setFormSubmitError('')
      })
      .catch(e => {
        sendCustomEvent(EventLabels.Event, EventNames.GET_LINK_CLICKED_ERRORED)
        setShareableLink('')
        setFormSubmitError('Something went wrong, please try again')
        console.log(e)
      })
      .finally(() => {
        setIsFormLoading(false)
      })
  }

  const onLinkCopyClicked = () => {
    sendClickEvent(EventLabels.CTA, EventNames.COPY_LINK_CLICKED)
    const success = copyToClipboard(shareableLink)
    if (success) {
      sendCustomEvent(EventLabels.Event, EventNames.COPY_LINK_CLICKED_SUCCESS)
      setIsLinkCopiedSuccess(true)
    } else {
      sendCustomEvent(EventLabels.Event, EventNames.COPY_LINK_CLICKED_ERRORED)
      setIsLinkCopiedSuccess(false)
    }
  }

  const onLinkFriendsCopyClicked = () => {
    sendClickEvent(EventLabels.CTA, EventNames.COPY_LINK_FRIENDS_CLICKED)
    const success = copyToClipboard(shareableLinkFriends)
    if (success) {
      sendCustomEvent(EventLabels.Event, EventNames.COPY_LINK_FRIENDS_CLICKED_SUCCESS)
      setIsLinkFriendsCopiedSuccess(true)
    } else {
      sendCustomEvent(EventLabels.Event, EventNames.COPY_LINK_FRIENDS_CLICKED_ERRORED)
      setIsLinkFriendsCopiedSuccess(false)
    }
  }

  return (
    <form className="form-horizontal"
      onSubmit={e => onFormSubmit(e)}
    >
      <fieldset>
        <EventTypeSelect
          eventTypeValue={formData.event_type}
          setEventTypeValue={value => {
            setFormData({
              ...formData,
              event_type: value
            })
          }}
        />


        {/* <EventDatePicker
          dateValue={formData.event_time}
          setDateValue={value => {
            setFormData({
              ...formData,
              event_time: value
            })
          }}
        /> */}

        <PersonNameInput
          personNameValue={formData.person_name}
          setPersonNameValue={value => {
            setFormData({
              ...formData,
              person_name: value
            })
          }}
        />

      {/* <GreetingMessageInput
        greetingMessageValue={formData.personal_msg}
        setGreetingMessageValue={value => {
          setFormData({
            ...formData,
            personal_msg: value
          })
        }}
      /> */}

      <EventSubmitButton isLoading={isFormLoading}/>

      { shareableLinkFriends && (
        <>
          <div className="field mb-3">
            1. Share this link with friends - <a classname="has-text-link is-underlined" href={shareableLinkFriends} target="_blank">{shareableLinkFriends}</a>
          </div>
          <div className="field mb-3">
            <span className="button mr-2 icon-text" onClick={onLinkFriendsCopyClicked}>
                <span>Copy Link</span>
                <span className="icon"><i className={"fas" + (isLinkFriendsCopiedSuccess ? " fa-check-circle" : " fa-copy")}/></span>
              </span>
              <a 
                className="button icon-text is-hidden-desktop is-hidden-tablet" 
                href={`whatsapp://send?text=Hey, wish ${formData.person_name} happy birthday with a custom message! ${shareableLinkFriends}`} 
                onClick={()=>sendClickEvent(EventLabels.CTA, EventNames.SHARE_LINK_FRIENDS_WHATSAPP_CLICKED)} 
                data-action="share/whatsapp/share"
              >
                <span>Share</span>
                <span className="icon"><i className="fab fa-whatsapp icon-unset-bounds"/></span>
              </a>
            </div>
        </>
      )}

      { shareableLinkFriends && (
        <>
          <div className="field mb-3">
            2. Friends leave their personal wishes for the birthday person
          </div>
        </>
      )}

      { shareableLink && (
        <>
          <div className="field mb-3">3. Lastly, share this link with the birthday person for them to cut cake & view all wishes - <a classname="has-text-link is-underlined" href={shareableLink} target="_blank">{shareableLink}</a></div> 
          <span className="button mr-2 icon-text" onClick={onLinkCopyClicked}>
            <span>Copy Link</span>
            <span className="icon"><i className={"fas" + (isLinkCopiedSuccess ? " fa-check-circle" : " fa-copy")}/></span>
          </span>
          <a 
            className="button icon-text is-hidden-desktop is-hidden-tablet" 
            href={`whatsapp://send?text=Wishing you a very happy birthday! ${shareableLink}`} 
            onClick={()=>sendClickEvent(EventLabels.CTA, EventNames.SHARE_LINK_WHATSAPP_CLICKED)} 
            data-action="share/whatsapp/share"
          >
            <span>Share</span>
            <span className="icon"><i className="fab fa-whatsapp icon-unset-bounds"/></span>
          </a>
        </>
      )}
      { formSubmitError && <div className="field has-text-danger">{formSubmitError}</div> }
    </fieldset>
  </form>

  );
}

export default CreatePartyForm;